import { useAuth0 } from "@auth0/auth0-react";
import {
	faArchive,
	faBars,
	faBrain,
	faCashRegister,
	faChartSimple,
	faComments,
	faFile, // Added for Archive
	faFileAlt, // Added for Chat
	faGavel,
	faSignOutAlt, // Added for Long Form
	faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { ArrowLeft } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as WealthLogo } from "../../assets/AdvisorX_logo.svg";
import WealthLogoShort from "../../assets/advisorx-logo.png";
import { useAppDispatch, useAppSelector } from "../../hooks.ts";
import "./Sidebar.css";

const menuItems = [
	{ title: "Playground", icon: faComments, path: "/" },
	{ title: "Advisor Canvas", icon: faCashRegister, action: "canvas" },
	{
		title: "Personas",
		icon: faBrain,
		path: "/personas",
	},
	{ title: "Long Form", icon: faFileAlt, path: "/long-form" },
	{ title: "Compliance", icon: faGavel, path: "/compliance" },
	{ title: "Charts", icon: faChartSimple, path: "/charts" },
	{ title: "Archive", icon: faArchive, path: "/archive" },
	{ title: "Document Analysis", icon: faFile, path: "/document-analysis" },
	{
		title: "PDF Search",
		icon: faUserTie,
		path: "/advisor-assist",
	},

	{ title: "Logout", icon: faSignOutAlt, action: "logout" },
];

const Sidebar = ({ currentPage, onPersonalizationClick }) => {
	const { logout } = useAuth0();
	const [isOpen, setIsOpen] = useState(true);
	const history = useHistory(); // Use the useHistory hook
	// const dispatch = useDispatch(); // Use the useDispatch hook for actions
	const dispatch = useAppDispatch(); // Use the useDispatch hook for actions
	const user = useAppSelector((state) => state.auth.user);
	const [isCanvasOpen, setIsCanvasOpen] = useState(false);
	const [isIframeLoading, setIsIframeLoading] = useState(true);

	useEffect(() => {
		const condition =
			user &&
			user.companyId &&
			user.companyId.toString() === "63854e726df8f59091075384";
		if (condition) {
			const complianceIndex = menuItems.findIndex(
				(item) => item.title === "Compliance"
			);
			if (complianceIndex !== -1) {
				menuItems.splice(complianceIndex, 1);
			}
		}
	}, [user]);
	const navigate = (path) => {
		history.push(path);
	};

	const handleItemClick = (item) => {
		if (item.action === "logout") {
			localStorage.clear();
			sessionStorage.clear();
			logout({
				logoutParams: {
					returnTo: "https://advisorx.ai",
				},
			});
		} else if (item.action === "canvas") {
			handleCanvasButton();
		} else {
			navigate(item.path);
		}
	};

	const handleCanvasButton = () => {
		const userId = user?._id;
		if (!userId) {
			alert("User session expired. Please login again.");
			return;
		}

		setIsCanvasOpen(true);
	};

	const handleIframeLoad = () => {
		setIsIframeLoading(false);
	};

	return (
		<div className={cx("sidebar", { "sidebar-closed": !isOpen })}>
			<div>
				<div className="flex-center-between">
					<button
						className={"sidebar__button"}
						onClick={() => setIsOpen(!isOpen)}
					>
						<FontAwesomeIcon icon={faBars} />
						{/*isOpen && (
            <img
            className={`advisor-x-logo-dark`}
            style={{ width: "100%" }}
            loading="lazy"
            src="https://assets-global.website-files.com/629e3357919ae1682f65b07c/6532d8b6ece624b92226971a_main-logo-big-advisorx.svg"
            alt=""
            />
            )*/}
					</button>
					{/* <div className="logo-button-wrapper">
						<button
							className="logo-button"
							onClick={handleCanvasButton}
						>
							<img
								src="/canvas-logo.png"
								style={{ width: "45px", borderRadius: "6px" }}
								alt="Canvas Logo"
							/>
						</button>
						<span className="logo-tooltip">Advisor Canvas</span>
					</div> */}
				</div>
				{isOpen ? <br /> : <></>}
				<ul>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<li style={{ marginTop: "10px" }}>
						{isOpen ? (
							<div className={"sidebar__listItem_logo"}>
								<WealthLogo />
							</div>
						) : (
							<div className="sidebar__icon">
								<img
									src={WealthLogoShort}
									style={{ width: "45px", borderRadius: "6px" }}
								/>
							</div>
						)}
					</li>
					<hr style={{ marginBottom: "20px" }} />
					{menuItems.map((item) => (
						<li
							key={item.title}
							onClick={() => handleItemClick(item)}
							className={
								currentPage === item.title?.toLowerCase()
									? "sidebar__listItem--selected"
									: ""
							}
						>
							<div className={"sidebar__listItem"}>
								<FontAwesomeIcon
									className={"sidebar__icon"}
									icon={item.icon}
								/>
								<CSSTransition
									in={isOpen}
									timeout={200}
									classNames={"fade"}
									unmountOnExit
								>
									<span>{item.title}</span>
								</CSSTransition>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				{isOpen && onPersonalizationClick ? (
					<div
						className="prompts-button"
						onClick={onPersonalizationClick ? onPersonalizationClick : () => {}}
					>
						Personalization
					</div>
				) : (
					<></>
				)}
			</div>
			{isCanvasOpen && (
				<div className="canvas-overlay">
					<button
						className="canvas-close-button"
						onClick={() => {
							setIsCanvasOpen(false);
							setIsIframeLoading(true); // Reset loading state on close
						}}
					>
						<ArrowLeft className="w-4 h-4" />
					</button>
					{isIframeLoading && (
						<div className="iframe-loader">
							<div className="spinner"></div>
							<p>Loading Canvas...</p>
						</div>
					)}
					<iframe
						src={`https://canvas.advisorx.ai?userId=${user?._id}`}
						// src={`http://localhost:3001?userId=${user?._id}`}
						title="Advisor Canvas"
						className="canvas-iframe"
						onLoad={handleIframeLoad}
						style={{ opacity: isIframeLoading ? 0 : 1 }}
					/>
				</div>
			)}
		</div>
	);
};

export default Sidebar;
