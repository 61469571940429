import { useState } from "react";

import { createKnowledgeBase } from "../services/knowledgebases.tsx";
import * as workflowServices from "../services/workflow";
import { updatedIncludeDomains } from "../utils/included_domains.ts";
import { useAuth0 } from "@auth0/auth0-react";

export function formatDomainsForDisplay(domains) {
  if (domains === null || domains === undefined) {
    return "";
  }
  let formattedDomains = "";
  for (let i = 0; i < domains.length; i++) {
    formattedDomains += domains[i];
    if (i < domains.length - 1) {
      formattedDomains += ", ";
    }
  }

  return formattedDomains;
}

export function convertTimeframeDaysToString(timeframeDays) {
  if (timeframeDays > 365) {
    return "any";
  } else if (timeframeDays === 1) {
    return "past_day";
  } else if (timeframeDays === 7) {
    return "past_week";
  } else if (timeframeDays === 30) {
    return "past_month";
  } else if (timeframeDays === 90) {
    return "past_quarter";
  } else if (timeframeDays === 365) {
    return "past_year";
  } else {
    return "any";
  }
}

export function formatTimeframeStringForDisplay(timeframe) {
  if (timeframe === "any") {
    return "Anytime";
  } else if (timeframe === "past_day") {
    return "Past day";
  } else if (timeframe === "past_week") {
    return "Past week";
  } else if (timeframe === "past_month") {
    return "Past month";
  } else if (timeframe === "past_quarter") {
    return "Past quarter";
  } else if (timeframe === "past_year") {
    return "Past year";
  } else {
    return "Anytime";
  }
}

export function convertTimeframeStringToDays(timeframe) {
  if (timeframe === "any") {
    return 10000;
  }
  if (timeframe === "past_day") {
    return 1;
  }
  if (timeframe === "past_week") {
    return 7;
  }
  if (timeframe === "past_month") {
    return 30;
  }
  if (timeframe === "past_quarter") {
    return 90;
  }
  if (timeframe === "past_year") {
    return 365;
  }
  return 10000;
}

export function formatWebSearchConfig(webSearchConfig) {
  // We only want to add in parameters if they are valid (not null or empty)
  const { useWebSearch, timeframe, includeDomains, excludeDomains } =
    webSearchConfig;

  if (!useWebSearch) {
    return {};
  }

  let formattedWebSearchConfig = {};
  if (timeframe !== null) {
    formattedWebSearchConfig["timeframe_days"] =
      convertTimeframeStringToDays(timeframe);
  }

  // Handle the include and exclude domains
  if (
    includeDomains !== null &&
    includeDomains !== undefined &&
    includeDomains.length > 0
  ) {
    // Convert the comma separated string to an array
    let includeDomainsArray = includeDomains.split(",");
    // Remove any whitespace
    includeDomainsArray.forEach((domain, index) => {
      includeDomainsArray[index] = domain.trim();
    });
    formattedWebSearchConfig["include_domains"] = includeDomainsArray;
  } else if (
    excludeDomains !== null &&
    excludeDomains !== undefined &&
    excludeDomains.length > 0
  ) {
    let excludeDomainsArray = excludeDomains.split(",");
    // Remove any whitespace
    excludeDomainsArray.forEach((domain, index) => {
      excludeDomainsArray[index] = domain.trim();
    });
    formattedWebSearchConfig["exclude_domains"] = excludeDomainsArray;
  }

  // Use the default include domains
  // formattedWebSearchConfig["include_domains"] = updatedIncludeDomains;
  // formattedWebSearchConfig["include_domains"] = includeDomains;

  return formattedWebSearchConfig;
}

export function useWorkflowHandler(token) {
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  async function createNewWorkflow(
    name,
    description,
    responseConfig,
    webSearchConfig
  ) {
    const { model, responseLength, writingStyle, systemMessage } =
      responseConfig;

    console.log("name", name);
    console.log("webSearchConfig", webSearchConfig);
    console.log("systemMessage", systemMessage);
    console.log("writingStyle", writingStyle);
    console.log("webSearchConfig", webSearchConfig);

    const formattedWebSearchConfig = formatWebSearchConfig(webSearchConfig);

    // Create a new knowledge base first

    const accessToken = await getAccessTokenSilently();
    const [kbResData, kbStatus] = await createKnowledgeBase(accessToken, name);
    if (kbStatus !== 200) {
      console.log("error creating knowledge base");
      return;
    }
    const kbID = kbResData.id;

    let payload = {
      name: name,
      description: description,
      system_message: systemMessage,
      writing_style: writingStyle,
      model: model,
      responseLength: responseLength,
      use_web_search: webSearchConfig.useWebSearch,
      knowledge_base_id: kbID,
      temperature: 0.4,
    };
    if (webSearchConfig.useWebSearch) {
      payload["web_search_preset"] = formattedWebSearchConfig;
    } else {
      payload["web_search_preset"] = {};
    }
    console.log("payload", payload);

    try {
      const [resData, status] = await workflowServices.createWorkflow(
        payload,
        token
      );
      // Add this to the list of workflows
      setWorkflows([...workflows, resData]);
      console.log("resData", resData);
    } catch (error) {
      console.log("error", error);
      alert("Error creating workflow: " + error.message);
    }
  }

  async function listWorkflows() {
    try {
      const accessToken = await getAccessTokenSilently();

      let workflows = await workflowServices.getWorkflows(accessToken);
      console.log("workflows", workflows);
      // Sort the workflows by created data (newest first)
      workflows.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
      setWorkflows(workflows);
      return workflows;
    } catch (error) {
      console.log("error", error);
      alert("Error getting workflows: " + error.message);
    }
    // Wait for 2 seconds, then try again
    /*await new Promise((r) => setTimeout(r, 2000));

    try {
      const workflows = await workflowServices.getWorkflows(token);
      console.log("workflows", workflows)
      setWorkflows(workflows);
      return workflows;
    } catch (error) {
      console.log("error", error)
      alert("Error getting workflows: " + error.message)
    }*/
  }

  async function getWorkflow(workflowId) {
    const workflow = await workflowServices.getWorkflow(workflowId, token);
    //setCurrentWorkflow(workflow);
    return workflow;
  }

  async function editWorkflow(
    workflowId,
    name,
    description,
    responseConfig,
    webSearchConfig,
    setWorkflow
  ) {
    console.log("workflowId", workflowId);
    const { model, responseLength, writingStyle, systemMessage } =
      responseConfig;
    const formattedWebSearchConfig = formatWebSearchConfig(webSearchConfig);

    let payload = {
      name: name,
      description: description,
      model: model,
      responseLength: responseLength,
      system_message: systemMessage,
      writing_style: writingStyle,
      use_web_search: webSearchConfig.useWebSearch,
    };
    if (webSearchConfig.useWebSearch) {
      payload["web_search_preset"] = formattedWebSearchConfig;
    }
    console.log("payload", payload);
    try {
      const assessToken = await getAccessTokenSilently();
      const resData = await workflowServices.updateWorkflow(
        workflowId,
        payload,
        assessToken
      );
      console.log("resData", resData);
      setWorkflow(resData);
      // Update the list of workflows
      const updatedWorkflows = workflows.map((workflow) => {
        if (workflow.id === workflowId) {
          return resData;
        }
        return workflow;
      });
      setWorkflows(updatedWorkflows);
      return resData;
    } catch (error) {
      alert("Error updating workflow");
      return null;
    }
  }

  async function deleteWorkflow(workflowId) {
    const assessToken = await getAccessTokenSilently();
    await workflowServices.deleteWorkflow(workflowId, assessToken);
    // Remove the workflow from the list of workflows
    const updatedWorkflows = workflows.filter((workflow) => {
      return workflow.id !== workflowId;
    });
    setWorkflows(updatedWorkflows);
  }

  return {
    createNewWorkflow,
    editWorkflow,
    listWorkflows,
    getWorkflow,
    deleteWorkflow,
    workflows,
    currentWorkflow,
  };
}
